<div class="container body-container">
  <div class="main-container">
    <h2>About Us</h2>
    <hr />

    <div>
      <div class="row">
        <div class="col-md-12">
          <h3>Know Us</h3>
          <p>CouriersHub is a hub for all courier, parcel and logistics services from all over the world. We
            provide services like tracking the documents/parcels, finding the available service in any
            courier service, volumetric weight calculation for large size parcels. Our website gives access
            to international courier services to domestic service. We also support e-commerce courier
            service. We will accept courier/parcel pickup from your doorsteps i.e you can book your courier
            from your home(online booking). We are supplying all the materials needed from the packing and
            handling the courier service. The primary feature of this CourierHub will feature the option to
            write review/feedback/testimonial/rate of any website listed on our website.
          </p>
          <p>
            Frontline Courier has been committed to providing our customers with exceptional delivery services that
            exceed expectations. We understand the importance of timely and secure delivery, whether you're sending
            important documents, urgent packages, or even gifts for loved ones. That's why we've partnered with all
            major service providers to offer a comprehensive range of shipping options to suit your needs.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h3>Our Mission</h3>
          <p>At Frontline Courier, our mission is simple: to provide our customers with the best possible delivery
            experience. We strive to be your trusted partner for all your shipping needs, ensuring that your documents
            and packages reach their destinations safely, on time, and within your budget.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h3>Our Values</h3>
          <p>Our core values guide our every action and decision:</p>
          <dl>
            <dt>Reliability</dt>
            <dd>We are committed to providing reliable delivery services that you can count on.</dd>
            <dt>Efficiency</dt>
            <dd>We strive to streamline our processes and eliminate unnecessary delays to ensure your shipments arrive
              promptly.</dd>
            <dt>Customer Satisfaction</dt>
            <dd>our satisfaction is our top priority. We are committed to exceeding your expectations and providing you
              with exceptional service.</dd>
          </dl>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h3>Our Vision</h3>
          <p>To become standard for all the courier/cargo/logistics related service featuring with Tracking
            service, Service checking, Volumetric weight calculation, rating the courier service, reviewing
            the courier service and so on..</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h3>Our Commitment to You</h3>
          <p>When you choose Frontline Courier to send your documents or non-documents, you can rest assured that they
            will be handled with care and delivered on time. We are your trusted partner for all your shipping needs,
            ensuring that your critical items reach their destinations seamlessly and without delay.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h3>Our Company</h3>
          <p>The CouriersHub is operated and maintained by the Frontline Courier which is located in Ambattur,
            Chennai. We are on of the leading courier service in Chennai. We have more than 100+ customers
            in Chennai. We provide services like Domestic, International, Air Cargo, Surface Cargo,
            Logistics etc.</p>
        </div>
      </div>
    </div>
  </div>
</div>