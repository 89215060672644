export const statusRelation = [
    {
      RelationId: 1,
      Name: 'Unknown',
      Description: 'unknown',
      Status: 1
    },
    {
      RelationId: 2,
      Name: 'FATHER',
      Description: 'FATHER',
      Status: 1
    },
    {
      RelationId: 3,
      Name: 'MOTHER',
      Description: 'MOTHER',
      Status: 1
    },
    {
      RelationId: 4,
      Name: 'BROTHER',
      Description: 'BROTHER',
      Status: 1
    },
    {
      RelationId: 5,
      Name: 'SISTER',
      Description: 'SISTER',
      Status: 1
    },
    {
      RelationId: 6,
      Name: 'GRAND FATHER',
      Description: 'GRAND FATHER',
      Status: 1
    },
    {
      RelationId: 7,
      Name: 'GRAND MOTHER',
      Description: 'GRAND MOTHER',
      Status: 1
    },
    {
      RelationId: 8,
      Name: 'NEIGHBOUR',
      Description: 'NEIGHBOUR',
      Status: 1
    },
    {
      RelationId: 9,
      Name: 'FRIEND',
      Description: 'FRIEND',
      Status: 1
    },
    {
      RelationId: 10,
      Name: 'CO STUDENT',
      Description: 'CO STUDENT',
      Status: 1
    },
    {
      RelationId: 11,
      Name: 'CO WORKER',
      Description: 'CO WORKER',
      Status: 1
    },
    {
      RelationId: 12,
      Name: 'STAFF',
      Description: 'STAFF',
      Status: 1
    },
    {
      RelationId: 13,
      Name: 'RECEPTION',
      Description: 'RECEPTION',
      Status: 1
    },
    {
      RelationId: 14,
      Name: 'MAIL ROOM',
      Description: 'MAIL ROOM',
      Status: 1
    },
    {
      RelationId: 15,
      Name: 'STORES / INCHARGE',
      Description: 'STORES / INCHARGE',
      Status: 1
    },
    {
      RelationId: 16,
      Name: 'DESPACH ROOM',
      Description: 'DESPACH ROOM',
      Status: 1
    },
    {
      RelationId: 17,
      Name: 'SECURITY',
      Description: 'SECURITY',
      Status: 1
    },
    {
      RelationId: 18,
      Name: 'NEAR HOUSE',
      Description: 'NEAR HOUSE',
      Status: 1
    },
    {
      RelationId: 19,
      Name: 'RELATION NOT MENTION',
      Description: 'RELATION NOT MENTION',
      Status: 1
    },
    {
      RelationId: 20,
      Name: 'SELF',
      Description: 'SELF',
      Status: 1
    },
    {
      RelationId: 21,
      Name: 'PARTNER',
      Description: 'PARTNER',
      Status: 1
    },
    {
      RelationId: 22,
      Name: 'Office Colleague',
      Description: 'Office Colleague',
      Status: 1
    },
    {
      RelationId: 23,
      Name: 'Family members',
      Description: 'NULL',
      Status: 1
    }
  ];