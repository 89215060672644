<div class="container body-container">
  <div class="main-container">
    <h2>Priority Service</h2>
    <hr />

    <div class="row">
      <div class="col-md-12">
        <h3>Priority 10:30 AM</h3>
        <p>A guaranteed door-to-door time definite delivery of shipments by air the next possible business day
          by 10:30 hours, targeted at time-critical business-to-business needs.</p>
        <ul>
          <li>
            An Express time definite service with Money Back Guarantee* (MBG) to the select Pin codes in
            Chennai, Delhi, Ahmedabad, Mumbai, Bangalore, Navi Mumbai and Gurgaon by 10:30 hrs on the next
            possible business day.
          </li>
          <li>
            Pickup service is available from Kolkata, Delhi, Gurgaon, Ghaziabad,Noida,Hyderabad,Chennai,
            Maraimalai Nagar, Sholinganallur, Bengaluru, Mumbai, Kolhapur,Navi Mumbai, Nagpur, Pune,
            Ahmedabad, Ankleshwar, Anand, Vadodara, Dewas, Indore,Mehsana, Rajkot, Surat, Udaipur, Vapi,
            Bhavnagar, Coimbatore, Godhra, Hubli, Jaipur and Kalol.
          </li>
          <li>
            Domestic Priority 1030 is not available from all local origins, or to all destinations
          </li>
          <li>
            Suitable for all retail and credit customers.
          </li>
          <li>
            Availability depends on local cut-off and vehicle arrival times, or flight arrival times when
            these apply.
          </li>
        </ul>

        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <a href="/service/pickup-request" class="btn btn-success">Pick-up Request</a>
          <a href="/wervice/get-quote" class="btn btn-primary">Get Quote</a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col fl-hr">
        <hr />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h3>Priority 12:00 PM</h3>
        <p>
          A guaranteed door-to-door time definite delivery of shipments by air the next possible business day
          by 12:00 hours, targeted at time-critical business-to-business needs.
        </p>
        <ul>
          <li>An Express time definite service with Money Back Guarantee* (MBG) to the select Pin codes in
            Pune, Vapi, Vadodara, Thane, Vasai, Noida, Hyderbad,Ghaziabad,Faridabad & Kolkata by 12:00 hrs
            on the next possible business day.</li>
          <li>Pickup service is available from Kolkata, Delhi, Gurgaon, Ghaziabad, Noida,Hyderabad,Chennai,
            Maraimalai Nagar, Sholinganallur, Bengaluru, Mumbai, Kolhapur,Navi Mumbai, Nagpur, Pune,
            Ahmedabad, Ankleshwar, Anand, Vadodara, Dewas, Indore,Mehsana, Rajkot, Surat, Udaipur, Vapi,
            Bhavnagar, Coimbatore, Godhra, Hubli, Jaipur and Kalol.</li>
          <li>Suitable for all retail and credit customers.</li>
          <li>Domestic Priority 1200 is not available from all local origins, or to all destinations.</li>
          <li>Availability depends on local cut-off and vehicle arrival times or flight arrival times when
            these apply.</li>
        </ul>

        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <a href="/service/pickup-request" class="btn btn-success">Pick-up Request</a>
          <a href="/wervice/get-quote" class="btn btn-primary">Get Quote</a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col fl-hr">
        <hr />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h3>Priority Next Day</h3>
        <p>A premium service for Documents and Parcels created for Guaranteed Next Business Day Delivery.
          Designed especially for time-sensitive documents & parcels.</p>
        <ul>
          <li>Assured next business day delivery with Money Back Guarantee</li>
          <li>
            Documents and parcels weighing in single pieces of up to 30 Kgs can be sent. Besides volumes can
            also be
            shipped with weight ranging from 1 Kg upwards to any desired load as may be required by the
            client.
          </li>
          <li>Consignment goes in a tamper proof safety pouch.</li>
          <li>Exclusive and dedicated team to handle your PRIORITY shipments.</li>
          <li>Dedicated delivery team to ensure time bound deliveries</li>
          <li>Proactive delivery information by way of real time SMS</li>
          <li>Wide range of pin codes covered. Please refer to our latest pin code search on the net.</li>
          <li>Signature of the recipient (scanned image of the POD) available on the net.</li>
          <li>Dedicated customer support team for your assistance.</li>
        </ul>

        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <a href="/service/pickup-request" class="btn btn-success">Pick-up Request</a>
          <a href="/wervice/get-quote" class="btn btn-primary">Get Quote</a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col fl-hr">
        <hr />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h3>Priority Sunday</h3>
        <p>Prime Time Plus Sunday: ensures all types of consignment pick up on the Saturday before 06:00 PM and
          delivery on Sunday, and is available for select pin-codes across the country.</p>

        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <a href="/service/pickup-request" class="btn btn-success">Pick-up Request</a>
          <a href="/wervice/get-quote" class="btn btn-primary">Get Quote</a>
        </div>
      </div>
    </div>
  </div>
</div>
