<div class="container body-container">
  <div class="main-container">
    <h2>Air Service</h2>
    <hr />

    <div class="row">
      <div class="col-md-12">
        <p>Shipment via Air mode. This mode will deliver your shipment anywhere in the world from domestic to
          international by flight.</p>

        <p>
          In today's fast-paced world, businesses and individuals rely on efficient and reliable transportation to
          connect with their global partners and customers. Air service has emerged as the preferred mode of
          transportation for shipments that demand speed and security. At [Company Name], we offer comprehensive air
          service solutions to ensure your shipments reach their destinations worldwide, safely and on time.
        </p>

        <p>
          Our air service solutions utilize the extensive network of airports and airlines worldwide, enabling us to
          deliver your shipments to any corner of the globe with unmatched speed and efficiency. We carefully coordinate
          and manage every aspect of the air transportation process, ensuring your cargo is handled with utmost care and
          delivered within the specified timeframe.
        </p>

        <p>
          We understand that every air freight shipment has its own unique requirements. That's why we offer customized
          air freight solutions tailored to the specific needs of your business. Whether you're shipping time-sensitive
          documents, delicate machinery, or high-value goods, our team of experts will design a seamless air freight
          solution that meets your specific transportation needs.
        </p>
        <a href="/contact" class="btn btn-success btn-sm">Contact Us</a>
      </div>
    </div>

    <app-common></app-common>
  </div>
</div>