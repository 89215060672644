<div class="container body-container">
  <div class="main-container">
    <h2>Cargo Service</h2>
    <hr />

    <div class="row">
      <div class="col-md-12">
        <p>Shipment via cargo mode. This mode will deliver your shipment with domestic via roadways transport.</p>

        <p>
          In the world of business, efficient and timely delivery is the cornerstone of success. When it comes to
          transporting large shipments, cargo shipping stands as a reliable and cost-effective mode of transportation.
          At [Company Name], we offer comprehensive cargo shipping services to ensure your shipments reach their
          destinations safely and on time.
        </p>

        <a href="/contact" class="btn btn-success btn-sm">Contact Us</a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h3>Benefits of Choosing Our Cargo Shipping Services:</h3>
        <ul>
          <li>
            Extensive Domestic Roadway Network: We leverage the vast network of roadways across the country to provide
            efficient and timely delivery within your domestic destinations.
          </li>
          <li>
            Tailored Cargo Solutions: Our expert team designs customized cargo shipping solutions to meet your specific
            requirements, ensuring your cargo is transported safely and efficiently.
          </li>
          <li>
            Unmatched Cargo Handling Expertise: Our experienced cargo handlers possess the skills and resources to
            handle even the most delicate and challenging cargo shipments.
          </li>
          <li>
            Real-Time Shipment Tracking: We provide real-time shipment tracking, allowing you to monitor the progress of
            your cargo and stay informed about its status.
          </li>
          <li>
            Comprehensive Insurance Coverage: We offer comprehensive insurance coverage to protect your cargo against
            any potential risks or damages during transportation.
          </li>
        </ul>
      </div>
    </div>

    <app-common></app-common>
  </div>
</div>