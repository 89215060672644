// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  reCaptcha: {
    siteKey: '6LcYoKcZAAAAAJ-DmAl6xi-ECgVpaWdDh-k0cMjN',
    secretKey: '6LcYoKcZAAAAAKvDfCpDzM528R8zrhTCkhvndIx4',
  },
  firebase: {
    apiKey: 'AIzaSyBM65EL-d5ppnBlGOZ5MYoMKVDtb0z-lKI',
    authDomain: 'varun-enterprises.firebaseapp.com',
    databaseURL: 'https://varun-enterprises.firebaseio.com',
    projectId: 'varun-enterprises',
    storageBucket: 'varun-enterprises.appspot.com',
    messagingSenderId: '627419010873',
    appId: '1:627419010873:web:054515e9f51513b160bae8',
    measurementId: 'G-QPTMSCRH73',
  },
  apiUrl: 'http://localhost:3000',
  apiPaths: {
    email: '/v1/email'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
