<div class="row">
  <div class="col-md-3">
    <h3>Air Delivery</h3>
    <p>
      Shipment via Air mode. This mode will deliver your shipment anywhere
      in the world from domestic to international by flight.
    </p>
    <p>
      Expeditious Delivery: Send your urgent documents and packages across
      the country with our lightning-fast air delivery service.
    </p>
    <p>
      Guaranteed Delivery: Rest assured that your critical items will arrive
      on time with our guaranteed air delivery options.
    </p>
  </div>
  <div class="col-md-3">
    <h3>Cargo Delivery</h3>
    <p>
      Shipment via cargo mode. This mode will deliver your shipment with
      domestic via roadways transport.
    </p>
    <p>
      Bulky Shipments: We expertly handle large-scale shipments, ensuring
      your cargo reaches its destination safely and efficiently.
    </p>
    <p>
      Tailored Solutions: Benefit from our customized cargo delivery
      solutions designed to meet your specific needs.
    </p>
  </div>
  <div class="col-md-3">
    <h3>Surface Delivery</h3>
    <p>
      Shipment via surface mode. This mode will deliver your shipment to
      mostly international. The mode of transport will be water.
    </p>
    <p>
      Cost-Effective Shipping: Enjoy affordable and reliable surface
      delivery for your non-urgent documents and packages.
    </p>
    <p>
      Nationwide Coverage: We provide seamless surface delivery services
      across the country, ensuring your items arrive safely.
    </p>
  </div>
  <div class="col-md-3">
    <h3>Other Service</h3>
    <p>
      Our service include Same-day delivery, Logistics, Supply Chain,
      Warehousing, Sea-port to Airport, Excess baggage and more...
    </p>
    <p>
      Same-Day Delivery: Need your documents or packages delivered within
      the same day? Our same-day delivery service is at your service.
    </p>
    <p>
      One-Day Delivery: Ensure your items arrive within 24 hours with our
      reliable one-day delivery option.
    </p>
    <p>
      Sunday Delivery: We offer convenient Sunday delivery services for
      those times when your delivery needs can't wait.
    </p>
  </div>

  <div class="row">
    <div class="col-md-12">
      <h3>Unmatched Partnership for Seamless Deliveries</h3>
      <p>
        We've partnered with all major service providers to provide you with
        access to the best possible shipping solutions. Our network of
        partners includes:
      </p>
      <ul>
        <li>Courier Services: Our extensive network of courier partners ensures your package reaches its
          destination
          quickly and securely.
        </li>
        <li>
          Postal Services: We offer discounted rates on postal services, making it even more affordable to
          send your
          documents and packages.
        </li>
        <li>
          Freight Forwarders: For larger shipments, we collaborate with experienced freight forwarders to
          handle the
          logistics and ensure your cargo arrives safely and on time.
        </li>
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <h3>Our Commitment to Your Delivery Success</h3>
      <p>
        At Frontline, we are committed to providing our customers with an exceptional shipping experience. We
        offer:
      </p>
      <ul>
        <li>
          Competitive Rates: Enjoy competitive rates on all our shipping services to make sending your
          documents and
          packages more affordable.
        </li>
        <li>
          Reliable Delivery: We track your shipment every step of the way to ensure it arrives on time and in
          perfect condition.
        </li>
        <li>
          Customer Support: Our dedicated customer support team is available to answer your questions and
          assist you
          with any shipping needs.
        </li>
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <h3>Send Your Documents and Packages with Confidence</h3>
      <p>
        When you choose [Company Name] to send your documents or non-documents, you can rest assured that they
        will
        be handled with the utmost care and delivered on time. We are your trusted partner for all your shipping
        needs, ensuring that your critical items reach their destinations seamlessly and without delay.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <h3>Contact Us Today</h3>
      <p>
        Our team of experts is here to help you choose the best shipping option for your needs. Contact us today
        for
        a free quote and experience the difference of Frontline's exceptional delivery services.
      </p>

      <a href="/contact" class="btn btn-success btn-sm">Contact Us</a>
    </div>
  </div>
</div>