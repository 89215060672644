<div class="container body-container">
  <div class="main-container">
    <h2>International Service</h2>
    <hr />

    <div class="row">
      <div class="col-md-12">
        <p>
          We provide delivery to almost all the country in the world at lowest price in market. We have parterned
        with all major service provider to provide reliable and fast delivery.
        </p>
        <p>
          In today's interconnected world, the ability to send documents, packages, and cargo across borders seamlessly is crucial for businesses and individuals alike. Whether you're an entrepreneur expanding your global reach or a student sending gifts to loved ones abroad, choosing a reliable and cost-effective shipping partner is essential.
        </p>
        <p>
          At Frontline, we understand the intricacies of international shipping and are committed to providing our customers with an exceptional delivery experience. We've partnered with all major service providers to offer an unmatched network of shipping solutions, ensuring your shipments reach their destinations worldwide safely, on time, and at the most competitive rates in the market.
        </p>

        <a href="/contact" class="btn btn-success btn-sm">Contact Us</a>
      </div>
    </div>

    <app-common></app-common>
  </div>
</div>