import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-domestic',
  templateUrl: './domestic.component.html',
  styleUrls: ['./domestic.component.scss']
})
export class DomesticComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
