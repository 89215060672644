<div class="container m-4 p-4">
    <section class="mb-5">
        <h2>Terms of Use</h2>

        <p>Welcome to Frontline Courier. These Terms of Use ("Terms") govern your use of the Frontline Courier website
            ("Website") and related services ("Services"). By using the Website or Services, you agree to be bound by
            these
            Terms.</p>

        <h4>1. Definitions</h4>

        <p>In these Terms, the following terms have the following meanings:</p>

        <ul class="list-unstyled">
            <li>"Content" means all information, data, text, software, images, audio, video, or other materials that are
                displayed on or made available through the Website or Services.</li>
            <li>"User" means any person or entity that accesses or uses the Website or Services.</li>
        </ul>

        <h4>2. User Conduct</h4>

        <p>You agree to use the Website and Services in a manner that is consistent with all applicable laws and
            regulations.</p>

        <p>You agree not to use the Website or Services for any unlawful purpose or in any way that could damage,
            disable,
            or impair the Website or Services, or interfere with the use of the Website or Services by any other User.
        </p>

        <h4>3. Intellectual Property</h4>

        <p>The Website and Services, including all Content, are protected by copyright, trademark, and other
            intellectual
            property laws. You agree not to copy, modify, create derivative works from, distribute, or exploit any
            Content
            without the express written permission of Frontline Courier.</p>

        <h4>4. Third-Party Links</h4>

        <p>The Website and Services may contain links to third-party websites and services. These links are provided for
            your convenience only and do not constitute an endorsement by Frontline Courier of any third-party website
            or
            service. Frontline Courier is not responsible for the content or privacy practices of any third-party
            website or
            service.</p>

        <h4>5. Privacy Policy</h4>

        <p>Our Privacy Policy explains how we collect, use, and disclose your personal information. Please review our
            Privacy
            Policy carefully before using the Website or Services.</p>

        <h4>6. Disclaimer of Warranties</h4>

        <p>THE WEBSITE AND SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
            IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            AND
            NON-INFRINGEMENT. FRONTLINE COURIER DISCLAIMS ALL LIABILITY FOR ANY DAMAGES ARISING OUT OF OR IN CONNECTION
            WITH
            YOUR
            USE OF THE WEBSITE OR SERVICES, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL,
            OR EXEMPLARY DAMAGES.</p>

        <h4>7. Limitation of Liability</h4>

        <p>IN NO EVENT SHALL FRONTLINE COURIER BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
            LOSS OF
            DATA OR PROFIT, OR DUE TO BUSINESS INTERRUPTION) ARISING OUT OF OR IN CONNECTION WITH THE USE OF OR
            INABILITY TO
            USE
            THE WEBSITE OR SERVICES, EVEN IF FRONTLINE COURIER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

        <h4>8. Indemnification</h4>

        <p>You agree to indemnify, defend, and hold Frontline Courier harmless from and against any and all claims,
            liabilities, damages, losses, costs, expenses, and fees (including reasonable attorneys' fees) arising out
            of or
            in connection with your use of the Website or Services, your violation of these Terms, or your violation of
            any
            applicable law or regulation.
        </p>

        <h4>9. General Provisions</h4>

        <p>
            These Terms are governed by and construed in accordance with the laws of the State of [State Name]. If any
            provision of these Terms is held to be invalid or unenforceable, such provision shall be struck from these
            Terms
            and the remaining provisions shall remain in full force and effect. These Terms constitute the entire
            agreement
            between you and Frontline Courier with respect to the subject matter hereof and supersede all prior or
            contemporaneous communications, representations, or agreements, whether oral or written. You may not assign
            these Terms without the prior written consent of Frontline Courier. Frontline Courier may modify these Terms
            at
            any time by posting the modified Terms on the Website. Your continued use of the Website or Services
            following
            the posting of modified Terms constitutes your acceptance of such modified Terms.


        </p>

        <p>
            Please contact us, if you have any questions about these Terms.
        </p>

        <h2>Privacy Policy</h2>

        <p>
            Frontline Courier is committed to protecting your privacy. This Privacy Policy explains how we collect, use,
            and
            disclose your personal information when you use our website, mobile app, or other services (collectively,
            the
            “Services”).
        </p>

        <h4>1. Information We Collect</h4>
        <p>We collect the following types of personal information from you:
        </p>
        <p>
            Information you provide to us. You may provide us with personal information such as your name, email
            address,
            phone number, and shipping address when you create an account, sign up for our newsletter, or place an
            order.
        </p>

        <p>Information we collect automatically. We may collect certain information about your use of the Services, such
            as
            your IP address, browser type, and referring website. We may also use cookies and other tracking
            technologies to
            collect information about your use of the Services.
        </p>

        <p>
            Information from third parties. We may collect information about you from third parties, such as our
            shipping
            partners.
        </p>

        <h4>1. Information We Collect</h4>
        <p>We use your personal information to:
        </p>
        <ul>
            <li>
                Provide the Services to you, including processing your orders and delivering your packages.
            </li>
            <li>
                Communicate with you about the Services, such as sending you order confirmations and shipping
                notifications.
            </li>
            <li>
                Improve the Services by analyzing user data and trends.
            </li>
            <li>
                Protect you from fraud and abuse.
            </li>
        </ul>

        <h4>2. How We Use Your Information
        </h4>
        <p>We use your personal information to:
        </p>
        <ul>
            <li>Provide the Services to you, including processing your orders and delivering your packages.
            </li>
            <li>Communicate with you about the Services, such as sending you order confirmations and shipping
                notifications.
            </li>
            <li>Improve the Services by analyzing user data and trends.
            </li>
            <li>Protect you from fraud and abuse.
            </li>
        </ul>

        <h4>3. How We Disclose Your Information
        </h4>
        <p>We may disclose your personal information to:
        </p>
        <ul>
            <li>Our service providers, such as our shipping partners and payment processors.
            </li>
            <li>Third parties who help us operate the Services, such as our website hosting provider.
            </li>
            <li>Law enforcement officials or other government officials if required by law.
            </li>
        </ul>

        <h4>4. Your Choices </h4>
        <p>
            You can control certain types of information that we collect about you. For example, you can adjust your
            browser
            settings to block cookies. You can also unsubscribe from our marketing emails by clicking on the
            “unsubscribe”
            link in any of our marketing emails.
        </p>

        <h4>5. Data Security </h4>
        <p>
            We take your privacy seriously and have implemented appropriate safeguards to protect your personal
            information.
            We use encryption, firewalls, and other security measures to protect your information from unauthorized
            access,
            disclosure, alteration, or destruction.
        </p>

        <h4>6. Changes to This Privacy Policy </h4>
        <p>
            We may update this Privacy Policy from time to time. We will notify you of any material changes by posting
            the
            updated Privacy Policy on our website.
        </p>

        <h4>7. Contact Us
        </h4>

        <p>If you have any questions about this Privacy Policy, please contact us </p>
    </section>
</div>