<div class="container body-container">
    <div class="main-container">
        <h2>Contact Us</h2>
        <hr />

        <div class="row">
            <div class="col-md-6">
                <h3>Ambattur O.T branches</h3>

                <address>
                    No 75, Chota Plaza Complex <br />
                    Mounaswamy Mada Street <br />
                    Ambattur O.T, Chennai <br />
                    Tamil Nadu - 600053 <br />
                    India <br />
                </address>

                <hr />

                <address>
                    412 MTH Road, Everest Complex <br />
                    Ground floor <br />
                    (Next to KFC Restaurant and RR Mobiles) <br />
                    Ambattur O.T, Chennai <br />
                    Tamil Nadu - 600053 <br />
                    India <br />
                </address>

                <hr />

                <address>
                    <strong>Email:</strong> <a href="mailto:contact@frontlinecourier.com">
                        contact&#64;frontlinecourier.com
                    </a><br />
                    <strong>Mobile:</strong> +91 8144111180 / 9941302000 / 9941312000
                </address>
            </div>

            <div class="col-md-6" *ngIf="mailCompleted">
                <div class="alert alert-success" role="alert" *ngIf="mailSent">
                    Email Send Successfully! Our Team will contact you.
                </div>
                <div class="alert alert-danger" role="alert" *ngIf="!mailSent">
                    Failed to send your request! Please contact us through email or phone.
                </div>
            </div>

            <div class="col-md-6" *ngIf="!mailCompleted">
                <iframe aria-label='Contact' frameborder="0" style="height:750px;width:99%;border:none;" src='https://forms.zohopublic.in/frontline/form/Contact/formperma/_k5O0zbR7wvk5gvaq3yNz0CQh718owuSEmFrgjn0Rpo'></iframe>
            </div>
        </div>
    </div>
</div>