<div class="container body-container">
    <div class="main-container">
        <h2>Reach Us</h2>
        <hr />


        <div class="row">
            <div class="col-md-6">
                <h3>Ambattur O.T branch</h3>
                <address>
                    No 75, Chota Plaza Complex <br />
                    Mounaswamy Mada Street <br />
                    Ambattur, Chennai - 600053 <br />
                    TN, India <br />
                </address>

                <h3>Ambattur Estate branch</h3>
                <address>
                    No 2, Menambedu Road <br />
                    Coromantel Town <br />
                    Ambattu SIDCO Estate <br />
                    (Next To South India Bank) <br />
                    Chennai - 600098 <br />
                    TN, India <br />
                </address>

                <address>
                    <strong>Email:</strong> <a
                        href="/cdn-cgi/l/email-protection#7300121f16005d15011c1d071f1a1d16101c06011a16010033141e121a1f5d101c1e53"><span
                            class="__cf_email__"
                            data-cfemail="ef9c8e838a9cc1899d80819b8386818a8c809a9d868a9d9caf88828e8683c18c8082">[email&#160;protected]</span>
                    </a><br />
                    <strong>Mobile:</strong> +91 8144111180 / 9941302000 / 9941312000
                </address>
            </div>

            <div class="col-md-6">
                <h3>Find us on: </h3>
                <span><a target="_blank"
                        href="https://www.justdial.com/chennai/Frontline-Courier-And-Cargo-OPP-CHANDRA-GAS-Ambattur/044PXX44-XX44-161207211501-K8W1_BZDET">JustDial</a></span>
                |
                <span><a target="_blank" href="https://www.facebook.com/frontlinecourier">Facebook</a></span> |
                <span><a target="_blank" href="https://plus.google.com/117100578149709916613">Google Plus</a></span>

                <h3>Locate Us:</h3>
                <div>
                    <iframe class="embed-responsive-item"
                        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d13069.596691289047!2d80.14138282145448!3d13.12360039422111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sFrontline+Courier!5e0!3m2!1sen!2sin!4v1499795672469"
                        width="400" height="250" frameborder="0" style="border:0" allowfullscreen></iframe>
                </div>
            </div>
        </div>

    </div>
</div>