<div class="container body-container">
    <div class="main-container">
        <h2>Domestic Booking Cost</h2>
        <hr />

        <div class="row">
            <div class="col-md-12">
                <table class="table table-striped table-bordered table-hover table-sm">
                    <thead class="thead-dark">
                        <tr class="table-sub-heading">
                            <th colspan="7"> Upto 5 KG</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Destination</th>
                            <th scope="col">Mode</th>
                            <th scope="col">Delivery</th>
                            <th scope="col">250 grams</th>
                            <th scope="col">500 grams</th>
                            <th scope="col">Addl 500 grams</th>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Chennai</td>
                            <td>Surface</td>
                            <td>1 Day</td>
                            <td>22</td>
                            <td>32</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Tamil Nadu, Puducherry</td>
                            <td>Surface</td>
                            <td>1 - 2 Days</td>
                            <td>55</td>
                            <td>70</td>
                            <td>30</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Andhra, Kerala, Karnataka (excluding Hyderabad)</td>
                            <td>Surface</td>
                            <td>1 - 3 Days</td>
                            <td>65</td>
                            <td>82</td>
                            <td>40</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Hyderabad, Secunderabad</td>
                            <td>Air</td>
                            <td>1- 2 Days</td>
                            <td>72</td>
                            <td>102</td>
                            <td>55</td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Hyderabad, Secunderabad - 3Kg</td>
                            <td>Surface</td>
                            <td>1 - 2 Days</td>
                            <td>174</td>
                            <td>174</td>
                            <td>174</td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <td>Delhi, Mumbai, Kolkata</td>
                            <td>Air</td>
                            <td>1 - 3 Days</td>
                            <td>77</td>
                            <td>107</td>
                            <td>65</td>
                        </tr>
                        <tr>
                            <th scope="row">7</th>
                            <td>North</td>
                            <td>Air</td>
                            <td>1 - 3 Days</td>
                            <td>87</td>
                            <td>112</td>
                            <td>70</td>
                        </tr>
                        <tr>
                            <th scope="row">8</th>
                            <td>Assam, Meghalaya, Mizoram, Tirupura, Andhaman, Port Blair</td>
                            <td>Air</td>
                            <td>2 - 5 Days</td>
                            <td>93</td>
                            <td>130</td>
                            <td>80</td>
                        </tr>
                    </tbody>
                    <thead class="thead-dark">
                        <tr class="table-sub-heading">
                            <th colspan="7">Air Cargo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Destination</th>
                            <th scope="col">Mode</th>
                            <th scope="col">Delivery</th>
                            <th scope="col">3 - 10 KG</th>
                            <th scope="col">11 - 25 KG</th>
                            <th scope="col">25 - 50 KG</th>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Hyderabad, Secunderabad</td>
                            <td>Air</td>
                            <td>2 - 3 Days</td>
                            <td>105</td>
                            <td>105</td>
                            <td>105</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Delhi, Mumbai, Kolkata</td>
                            <td>Air</td>
                            <td>2 - 3 Days</td>
                            <td>125</td>
                            <td>125</td>
                            <td>125</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>North</td>
                            <td>Air</td>
                            <td>2 - 3 Days</td>
                            <td>145</td>
                            <td>145</td>
                            <td>145</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Assam, Meghalaya, Mizoram, Tirupura, Andhaman, Port Blair</td>
                            <td>Air</td>
                            <td>3 - 6 Days</td>
                            <td>145</td>
                            <td>145</td>
                            <td>145</td>
                        </tr>
                    </tbody>
                    <thead class="thead-dark">
                        <tr class="table-sub-heading">
                            <th colspan="7">Surface Courier (Minimum 3 KG)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Destination</th>
                            <th scope="col">Mode</th>
                            <th scope="col">Delivery</th>
                            <th scope="col">3 - 10 KG</th>
                            <th scope="col">11 - 25 KG</th>
                            <th scope="col">25 - 50 KG</th>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Chennai</td>
                            <td>Surface</td>
                            <td>1 Day</td>
                            <td>22</td>
                            <td>16</td>
                            <td>16</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Tamil Nadu, Puducherry</td>
                            <td>Surface</td>
                            <td>1 - 2 Days</td>
                            <td>38</td>
                            <td>27</td>
                            <td>27</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Andhra, Kerala, Karnataka</td>
                            <td>Surface</td>
                            <td>2 - 4 Days</td>
                            <td>48</td>
                            <td>37</td>
                            <td>37</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Hyderabad, Secunderabad</td>
                            <td>Surface</td>
                            <td>2 - 3 Days</td>
                            <td>60</td>
                            <td>48</td>
                            <td>48</td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Delhi, Mumbai, Kolkata</td>
                            <td>Surface</td>
                            <td>5 - 7 Days</td>
                            <td>70</td>
                            <td>58</td>
                            <td>85</td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <td>North</td>
                            <td>Surface</td>
                            <td>6 - 8 Days</td>
                            <td>80</td>
                            <td>68</td>
                            <td>68</td>
                        </tr>
                        <tr>
                            <th scope="row">7</th>
                            <td>Assam, Meghalaya, Mizoram, Tirupura, Andhaman, Port Blair</td>
                            <td>Surface</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                    <thead class="thead-dark">
                        <tr class="table-sub-heading">
                            <th colspan="7">Air Cargo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Destination</th>
                            <th scope="col">Mode</th>
                            <th scope="col">500 grams</th>
                            <th scope="col">Addl 500 grams</th>
                            <th>-</th>
                            <th>-</th>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Chennai</td>
                            <td>Surface</td>
                            <td>120</td>
                            <td>55</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Tamil Nadu, Puducherry</td>
                            <td>Surface</td>
                            <td>150</td>
                            <td>100</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Andhra, Kerala, Karnataka, Telangana</td>
                            <td>Air/ Surface</td>
                            <td>180</td>
                            <td>115</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Rest of India</td>
                            <td>Air</td>
                            <td>200</td>
                            <td>135</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Holiday Delivery</td>
                            <td>Air/ Surface</td>
                            <td>275</td>
                            <td>160</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
</div>