<div class="container">

  <!-- row 1 -->
  <div class="row shadow rounded p-4 m-4 text-light bg-dark">
    <div class="col-md-6">
      <h2>Welcome to Frontline Courier!</h2>
      <p>We provide world-wide courier/cargo and fright forwarding service all over the world.</p>
      <p><a class="btn btn-primary btn-lg" href="/contact">Contact Us </a></p>
    </div>
    <div class="col-md-6">
      <div class="track-form ">
        <h2>Track your shipment</h2>
        <form [formGroup]="trackForm" autocomplete="off">
          <div class="mb-3">
            <input type="text" formControlName="id" name="id" class="form-control" id="trackTextBox2"
              placeholder="Search POD/Reference number" value="" required="">
          </div>
          <div class="mb-3">
            <select class="form-select" id="track" name="track" aria-label="track" formControlName="track">
              <option value="1" selected>POD Number</option>
              <option value="2">Reference Number</option>
            </select>
          </div>
          <div class="d-grid">
            <input class="btn btn-success btn-block pull-right" value="Track" id="trackBtn2" (click)="gotoTrack()">
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col fl-hr">
      <hr />
    </div>
  </div>

  <div class="row">
    <div class="col-md-4 ">
      <h2>Domestic Service</h2>
      <p>Send your document or non-document to anywhere within your country on time. We have parterned with all
        major service provider to provide reliable and fast delivery.</p>
      <p><a class="btn btn-success" href="/service/domestic">Read more &raquo;</a></p>
    </div>
    <div class="col-md-4">
      <h2>International Service</h2>
      <p>We provide delivery to almost all the country in the world at lowest price in market. We have parterned
        with all major service provider to provide reliable and fast delivery.</p>
      <p><a class="btn btn-success" href="/service/international">Read more &raquo;</a></p>
    </div>
    <div class="col-md-4">
      <h2>Cargo Service</h2>
      <p>Shipment via cargo mode. This mode will deliver your shipment with domestic via roadways transport.</p>
      <p><a class="btn btn-success" href="/service/cargo">Read more &raquo;</a></p>
    </div>
  </div>

  <div class="row">
    <div class="col fl-hr">
      <hr />
    </div>
  </div>

  <app-common></app-common>

  <div class="row">
    <div class="col fl-hr">
      <hr />
    </div>
  </div>

  <div class="row home-service">
    <div class="col-md-12">
      <h3>Priority Services</h3>
      <div class="row">
        <div class="col-md-3">
          <h4>Priority 10:30 AM</h4>
          <p>
            A guaranteed door-to-door time definite delivery of shipments by air the next possible business
            day by 10:30 hours, targeted at time-critical business-to-business needs.

          </p>
          <a href="/service/priority" class="btn btn-success btn-sm">Read More..</a>
        </div>
        <div class="col-md-3">
          <h4>Priority 12:00 PM</h4>
          <p>
            A guaranteed door-to-door time definite delivery of shipments by air the next possible business
            day by 12:00 hours, targeted at time-critical business-to-business needs.

          </p>
          <a href="/service/priority" class="btn btn-success btn-sm">Read More..</a>
        </div>
        <div class="col-md-3">
          <h4>Priority Sunday</h4>
          <p>
            This service ensures all types of consignment pick up on the Saturday before 06:00 PM and
            delivery on Sunday, and is available for select pin-codes across the country.
          </p>
          <a href="/service/priority" class="btn btn-success btn-sm">Read More..</a>
        </div>
        <div class="col-md-3">
          <h4>Priority Next Day</h4>
          <p>A premium service for Documents and Parcels created for Guaranteed Next Business Day Delivery.
            Designed especially for time-sensitive documents & parcels.</p>
          <a href="/service/priority" class="btn btn-success btn-sm">Read More..</a>
        </div>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col fl-hr">
      <hr />
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <h3>Volumetric weight calculation</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10">
      <p>Dimensional weight, also known as volumetric weight, is a pricing technique for commercial freight
        transport (including courier and postal services), which uses an estimated weight that is calculated
        from the length, width and height of a package.</p>
      <p>The volumetric weight of a shipment is a calculation that reflects the density of a package. A less dense
        item generally occupies more volume of space, in comparison to its actual weight. The volumetric or
        dimensional weight is calculated and compared with the actual weight of the shipment to ascertain which
        is greater; the higher weight is used to calculate the shipment cost.</p>
    </div>
    <div class="col-md-2">
      <a href="/service/volumetric-weight-calculation" class="pull-right btn btn-success">Calcualte Now</a>
    </div>
  </div>

</div>