<div class="container body-container">
  <div class="main-container">
    <h2>Domestic Service</h2>
    <hr />

    <div class="row">
      <div class="col-md-12">
        <p>
          Send your document or non-document to anywhere within your country on
          time. We have parterned with all major service provider to provide
          reliable and fast delivery.
        </p>
        <p>
          Experience Unmatched Efficiency and Reliability with Our Nationwide
          Delivery Services
        </p>
        <p>
          In today's fast-paced world, timely and dependable delivery is crucial
          for businesses and individuals alike. Whether you're sending important
          documents, urgent packages, or even gifts for loved ones, choosing a
          shipping partner that can consistently deliver your goods on time and
          in perfect condition is paramount.
        </p>
        <p>
          At Frontline Courier, we understand the importance of meeting your
          delivery needs with precision and care. That's why we've meticulously
          crafted a comprehensive range of shipping options to suit your
          specific requirements, ensuring that your documents and non-documents
          reach their destinations seamlessly.
        </p>
        <p>
          We offer a diverse array of shipping services to cater to your unique
          requirements
        </p>

        <a href="/contact" class="btn btn-success btn-sm">Contact Us</a>
      </div>
    </div>

    <app-common></app-common>
  </div>
</div>