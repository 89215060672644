<div class="container body-container">
  <div class="main-container">
    <h2>Surface Service </h2>
    <hr />

    <div class="row">
      <div class="col-md-12">
        <p>Shipment via surface mode. This mode will deliver your shipment to mostly international. The mode of
          transport will be water.
        </p>

        <p>
          In the realm of global trade, surface shipping stands as a cornerstone of efficient and cost-effective freight transportation. When it comes to transporting large shipments across vast distances, ocean freight offers a compelling combination of reliability, affordability, and environmental sustainability. At [Company Name], we provide comprehensive surface shipping courier services, ensuring your shipments reach their international destinations safely and on time.
        </p>

        <p>
          Our surface shipping courier services leverage the expansive network of seaports and shipping routes around the world, enabling us to deliver your cargo to virtually any international destination. We carefully plan and execute every step of the process, ensuring your shipments are handled with utmost care and delivered within the specified timeframe.
        </p>

        <p>
          We understand that every international shipment has its own unique requirements. That's why we offer customized surface shipping courier solutions to cater to the specific needs of your business. Our team of experts will carefully assess your cargo's characteristics and provide tailored solutions that meet your specific transportation needs, whether it's transporting raw materials, manufactured goods, or sensitive equipment.
        </p>

        <p>
          Our experienced team of surface shipping professionals possesses the expertise and resources to handle even the most intricate and challenging ocean freight shipments. We adhere to industry-best practices and utilize specialized equipment to ensure your cargo is transported safely and efficiently throughout its journey across the seas.
        </p>

        <a href="/contact" class="btn btn-success btn-sm">Contact Us</a>
      </div>
    </div>

    <app-common></app-common>

    <div class="row">
      <div class="col fl-hr">
        <hr />
      </div>
    </div>

  </div>
</div>