<div class="container body-container">
    <div class="main-container">
        <h2>Get Quotation</h2>
        <hr />

        <div class="row">
            <div class="col-md-12">
                <p style="color: red;">Please contact us for quotes.</p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-10">
                <form class="form-horizontal" method="post">
                    <fieldset>
                        <div>
                            <div class="form-group row">
                                <label for="contactName" class="col-md-3">Contact Person Name: </label>
                                <div class="col-md-3">
                                    <input type="text" name="contactName" class="form-control" placeholder="Your Name"
                                        required="required" />
                                </div>
                                <label for="companyName" class="col-md-3">Company Name: (if any) </label>
                                <div class="col-md-3">
                                    <input type="text" name="companyName" class="form-control"
                                        placeholder="Company Name" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="email" class="col-md-3">Email: </label>
                                <div class="col-md-3">
                                    <input type="email" name="email" class="form-control" placeholder="Your Email"
                                        required="required" />
                                </div>
                                <label for="mobile" class="col-md-3">Mobile: </label>
                                <div class="col-md-3">
                                    <input type="text" name="mobile" class="form-control"
                                        placeholder="Your Contact Number" required="required" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="fromLocation" class="col-md-3">Pickup Location: </label>
                                <div class="col-md-3">
                                    <input type="text" name="fromLocation" class="form-control"
                                        placeholder="Your City or Pincode" required="required" />
                                </div>
                                <label for="toLocation" class="col-md-3">Delivery Location: </label>
                                <div class="col-md-3">
                                    <input type="text" name="toLocation" class="form-control"
                                        placeholder="Destination City or Pincode" required="required" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="shipmentCount" class="col-md-2">No. of shipment: </label>
                                <div class="col-md-2">
                                    <input type="number" name="shipmentCount" class="form-control"
                                        placeholder="No. of piece" required="required" min="1" />
                                </div>
                                <label for="totalWeight" class="col-md-2">Total Weight: </label>
                                <div class="col-md-2">
                                    <input type="text" name="totalWeight" class="form-control"
                                        placeholder="Total weight in Kg" />
                                </div>
                                <label for="volumetricWeight" class="col-md-1">Volume Weight (in CM): </label>
                                <div class="col-md-1">
                                    <input type="text" name="volumeL" class="form-control" placeholder="L" />
                                </div>
                                <div class="col-md-1">
                                    <input type="text" name="volumeB" class="form-control" placeholder="B" />
                                </div>
                                <div class="col-md-1">
                                    <input type="text" name="volumeH" class="form-control" placeholder="H" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="contentType" class="col-md-2">Content of Goods: </label>
                                <div class="col-md-2">
                                    <input type="text" name="contentType" class="form-control" placeholder="Content"
                                        required="required" />
                                </div>
                                <label for="contentWorth" class="col-md-2">Worth of Goods: </label>
                                <div class="col-md-2">
                                    <input type="text" name="contentWorth" class="form-control"
                                        placeholder="Total worth of goods" />
                                </div>
                                <label for="contentMode" class="col-md-2">Classification of Goods: </label>
                                <div class="col-md-2">
                                    <select name="contentMode" class="form-control">
                                        <option value="Non Commerical">Non Commerical</option>
                                        <option value="Commerical">Commerical</option>
                                        <option value="Sample">Sample</option>
                                        <option value="Gift">Gift</option>
                                        <option value="Personal">Personal</option>
                                        <option value="Returnable Item">Returnable Item</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="remarks" class="col-md-3">Remarks/Comments: (if any) </label>
                                <div class="col-md-9">
                                    <textarea name="remaks" class="form-control"
                                        placeholder="any other additional information"></textarea>
                                </div>
                            </div>

                            <div class="d-flex justify-content-end">
                                <button type="submit" class="btn btn-success">Get Quotation </button>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col fl-hr">
                <hr />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <h4>Quick Information</h4>
                <p>You can calculate volumetric weight from here: <a target="_blank"
                        href="/service/volumetric-weight-calculation">click here</a></p>
                <p>You can find our pricing details here: <a target="_blank" href="/Service/Pricing">click here</a></p>
            </div>
        </div>

    </div>
</div>
