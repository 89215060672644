<div class="container body-container">
    <div class="main-container">
        <h2>Volumetric Weight Calculation</h2>
        <hr />

        <form name="volumetric" id="volumetric" role="form" method="post">

            <div class="row">
                <div class="col-md-4">
                    <h4>Choose the courier</h4><br />
                    <div class="form-group">
                        <label for="courier">Courier</label>
                        <select name="courier" id="courier" class="form-control" required autofocus tabindex="1">
                            <option disabled hidden selected value="100"> -- </option>
                            <option value="1">Aramex</option>
                            <option value="2">Bluedart</option>
                            <option value="3">DHL</option>
                            <option value="4">DTDC</option>
                            <option value="5">Fedex</option>
                            <!-- <option value="6">First Flight</option> -->
                            <option value="7">French Express</option>
                            <!-- <option value="8">Ondot</option> -->
                            <option value="9">Overnite</option>
                            <option value="10">Trackon</option>
                            <option value="11">TNT</option>
                            <option value="12">UPS</option>
                            <option value="13">SkyKing</option>
                            <option value="14">Shree Tirupati</option>
                            <option value="15">Shree Maruthi</option>
                            <option value="16">Shree Anjani</option>
                            <option value="17">Maruthi</option>
                            <!-- <option value="18">Madhur Courier</option> -->
                            <!-- <option value="19">Poonam Courier</option> -->
                        </select>
                    </div>


                    <div class="form-group">
                        <label for="shipping">Shipping</label>
                        <select name="shipping" id="shipping" class="form-control" required="required">
                            <option disabled selected hidden value=""> -- </option>
                            <option value="0">Domestic</option>
                            <option value="1">International</option>
                        </select>
                    </div>


                    <div class="form-group">
                        <label for="transport">Transport</label>
                        <select name="transport" id="transport" class="form-control" required="required" tabindex="2">
                            <option disabled hidden selected value=""> -- </option>
                            <option value="1">Air</option>
                            <option value="2">Surface</option>
                            <option value="3">Cargo</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="delivery">Delivery Modes</label>
                        <select name="delivery" id="delivery" class="form-control" required="required" tabindex="3">
                            <option disabled hidden selected value="0"> -- </option>
                            <option value="0">N/A</option>
                            <option value="1">Fr</option>
                            <option value="2">Economy</option>
                            <option value="3">Express</option>
                        </select>
                    </div>
                </div>

                <div class="col-md-4">
                    <h4>Provide the values</h4><br />
                    <div class="form-group">
                        <label title="Length">Length (CM):</label>
                        <input type="number" min="1" max="99999"
                            name="length" id="length" autocomplete="off" required class="form-control"
                            tabindex="3" />
                    </div>
                    <div class="form-group">
                        <label title="Width">Width (CM):</label>
                        <input type="number" min="1" max="99999"
                            name="width" id="width" autocomplete="off" required class="form-control" tabindex="4" />
                        
                    </div>
                    <div class="form-group">
                        <label title="Height">Height (CM):</label>
                        <input type="number" min="1" max="99999"
                            name="height" id="height" autocomplete="off" required class="form-control" tabindex="5" />
                    </div>
                </div>

                <div class="col-md-4 clearfix">
                    <h4>Your volumetric weight:</h4>
                    <div class="jumbotron text-center">
                        <div>
                            <div id="volOutputGroup"><span id="volOutput" tabindex="6">0.00</span> <span>KG</span></div>
                            <span id="error-msg"></span> <br />
                            <span id="dimFactor"></span> <br />
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <hr />

        <div class="row">
            <div class="col-md-12">
                <h4>What is Volumetric Weight?</h4>
                <p>Dimensional weight, also known as volumetric weight, is a pricing technique for commercial freight
                    transport (including courier and postal services), which uses an estimated weight that is calculated
                    from the length, width and height of a package.</p>
                <p>The volumetric weight of a shipment is a calculation that reflects the density of a package. A less
                    dense item generally occupies more volume of space, in comparison to its actual weight. The
                    volumetric or dimensional weight is calculated and compared with the actual weight of the shipment
                    to ascertain which is greater; the higher weight is used to calculate the shipment cost.</p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <h4>How to calculate the Volumetric Weight</h4>
                <ul>
                    <li>Measure the length*/ width and height of the consignment in centimeters.</li>
                    <li>
                        Calculate the volume and divide it by 5,000 to get the dimensional weight in kilograms.
                        (Note: Round off fractions to the next whole number)
                    </li>
                    <li>If the dimensional weight is greater than the consignment’s actual weight, use the dimensional
                        weight to calculate the rate.</li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <h4>Volumetric Weights Formula</h4>
                <table class="table table-striped table-hover table-bordered ">
                    <thead>
                        <tr>
                            <td colspan="2"></td>
                            <td>Air - Domestic</td>
                            <td>Air - International</td>
                            <td>Surface</td>
                            <td>Cargo</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowspan="3">Aramex</td>
                            <td>Economy</td>
                            <td>n/a</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>n/a</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>n/a</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">Bluedart</td>
                            <td>Economy</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxHx9)/27000</td>
                            <td>(LxBxHx9)/27000</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxHx9)/27000</td>
                            <td>(LxBxHx9)/27000</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxHx9)/27000</td>
                            <td>(LxBxHx9)/27000</td>
                        </tr>
                        <tr>
                            <td rowspan="3">DHL</td>
                            <td>Economy</td>
                            <td>n/a</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>n/a</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>n/a</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">DTDC</td>
                            <td>Economy</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/4750</td>
                            <td>(LxBxHx9)/27000</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/4750</td>
                            <td>(LxBxHx9)/27000</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/4750</td>
                            <td>(LxBxHx9)/27000</td>
                        </tr>
                        <tr>
                            <td rowspan="3">Fedex</td>
                            <td>Economy</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxHx9)/27000</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxHx9)/27000</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxHx9)/27000</td>
                        </tr>
                        <tr>
                            <td rowspan="3">French</td>
                            <td>Economy</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">Overnite Exp</td>
                            <td>Economy</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/4000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/4000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/4000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">Trackon</td>
                            <td>Economy</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">TNT</td>
                            <td>Economy</td>
                            <td>n/a</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>n/a</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>n/a</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">UPS</td>
                            <td>Economy</td>
                            <td>n/a</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>n/a</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>n/a</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">SkyLink</td>
                            <td>Economy</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">Shree Tirupathi</td>
                            <td>Economy</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">Shree Maruthi</td>
                            <td>Economy</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">Shree Anjani</td>
                            <td>Economy</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">Maruthi Courier</td>
                            <td>Economy</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">XpressBee Cargo</td>
                            <td>Economy</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">Delhivery Courier</td>
                            <td>Economy</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td rowspan="3">Delhivery Cargo</td>
                            <td>Economy</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>Express</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                        <tr>
                            <td>FR</td>
                            <td>(LxBxH)/6000</td>
                            <td>(LxBxH)/5000</td>
                            <td>(LxBxH)/5000</td>
                            <td>n/a</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</div>